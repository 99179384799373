import { BigNumber } from 'bignumber.js';

export type BigNumberish = BigNumber.Value;

export const BIG_ZERO = new BigNumber(0);
export const BIG_ONE = new BigNumber(1);

export function toBigNumber(input: BigNumberish): BigNumber {
  if (BigNumber.isBigNumber(input)) {
    return input;
  }

  return new BigNumber(input);
}

export function isBigNumber(value: any): value is BigNumber {
  return BigNumber.isBigNumber(value);
}

export function formatTokenAmount(amount: BigNumber) {
  return amount.toString(10);
}

export function toTokenDecimals(amount: BigNumberish, decimals: number): BigNumber {
  return toBigNumber(amount).shiftedBy(-decimals).decimalPlaces(decimals, BigNumber.ROUND_FLOOR);
}

/**
 * @param value 0-1
 */
export function formatPercent(value: BigNumber): string {
  return value.multipliedBy(100).decimalPlaces(2).toString(10);
}
