import { memo, useEffect } from 'react';
import { useAppDispatch } from '../data/hooks';
import { appInit } from '../data/actions/app';

export const Data = memo(function Data() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(appInit());
  }, [dispatch]);

  return null;
});
