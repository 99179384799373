import { createSlice } from '@reduxjs/toolkit';
import { PotDataEntity, PotEntity, PotStrategyEntity, PotTicketEntity } from '../entities/pot';
import { potsFetchContractDataByNetwork, potsInit } from '../actions/pots';
import { NetworkEntity } from '../entities/network';

export type PotState = {
  allIds: PotEntity['id'][];
  byId: Record<PotEntity['id'], PotEntity>;
  byNetworkId: Record<NetworkEntity['id'], PotEntity['id'][]>;
  strategyById: Record<PotEntity['id'], PotStrategyEntity>;
  dataById: Record<PotEntity['id'], PotDataEntity>;
  ticketById: Record<PotEntity['id'], PotTicketEntity>;
};

const initialState: PotState = {
  allIds: [],
  byId: {},
  byNetworkId: {},
  strategyById: {},
  dataById: {},
  ticketById: {},
};

const potsSlice = createSlice({
  name: 'pots',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(potsInit.fulfilled, (sliceState, action) => {
        for (const [network, pots] of Object.entries(action.payload)) {
          if (!(network in sliceState.byNetworkId)) {
            sliceState.byNetworkId[network] = [];
          }

          for (const pot of pots) {
            if (!(pot.id in sliceState.byId)) {
              sliceState.byId[pot.id] = {
                ...pot,
                network,
              };
              sliceState.allIds.push(pot.id);
              sliceState.byNetworkId[network].push(pot.id);
            } else {
              console.error(`Duplicate pot id ${pot.id}`);
            }
          }
        }
      })
      .addCase(potsFetchContractDataByNetwork.fulfilled, (sliceState, action) => {
        for (const pot of action.payload) {
          sliceState.strategyById[pot.id] = pot.strategy;
          sliceState.dataById[pot.id] = pot.data;
          sliceState.ticketById[pot.id] = pot.ticket;
        }
      });
  },
});

export const potsReducer = potsSlice.reducer;
