import { createSlice } from '@reduxjs/toolkit';
import { NetworkEntity } from '../entities/network';
import { TokenEntity } from '../entities/token';
import { balanceFetch } from '../actions/balance';

export type BalanceState = {
  byWallet: {
    [address: string]: {
      byNetwork: {
        [network: NetworkEntity['id']]: {
          byAddress: {
            [address: TokenEntity['address']]: {
              balance: number;
              byId: {
                [id: string]: number;
              };
              allIds: string[];
            };
          };
        };
      };
    };
  };
};

const initialState: BalanceState = {
  byWallet: {},
};

const balanceSlice = createSlice({
  name: 'balance',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(balanceFetch.fulfilled, (sliceState, action) => {
      const { tokenAddress, networkId, walletAddress } = action.meta.arg;
      const wallet = walletAddress.toLowerCase();
      const token = tokenAddress.toLowerCase();

      if (!(wallet in sliceState.byWallet)) {
        sliceState.byWallet[wallet] = {
          byNetwork: {},
        };
      }

      if (!(networkId in sliceState.byWallet[wallet].byNetwork)) {
        sliceState.byWallet[wallet].byNetwork[networkId] = {
          byAddress: {},
        };
      }

      if (!(token in sliceState.byWallet[wallet].byNetwork[networkId].byAddress)) {
        sliceState.byWallet[wallet].byNetwork[networkId].byAddress[token] = {
          balance: 0,
          byId: {},
          allIds: [],
        };
      }

      sliceState.byWallet[wallet].byNetwork[networkId].byAddress[token].balance =
        action.payload.balance;
      sliceState.byWallet[wallet].byNetwork[networkId].byAddress[token].byId = action.payload.byId;
      sliceState.byWallet[wallet].byNetwork[networkId].byAddress[token].allIds = Object.keys(
        action.payload.byId
      );
    });
  },
});

export const balanceReducer = balanceSlice.reducer;
