import { createSelector } from '@reduxjs/toolkit';
import { PotEntity, PotStrategyAwardEntity } from '../entities/pot';
import createCachedSelector from 're-reselect';
import { NetworkEntity } from '../entities/network';
import { dateFromTimestamp } from '../../../utils/date';
import { RootState } from '../store-types';
import { selectTokenByNetworkAddress } from './tokens';
import { BigNumber } from 'bignumber.js';
import { TokenEntity } from '../entities/token';
import { toTokenDecimals } from '../../../utils/bignumber';
import { isAfter } from 'date-fns';

export const selectPotIds = (state: RootState) => state.entities.pots.allIds;

export const selectAllPots = createSelector(
  (state: RootState) => state.entities.pots.allIds,
  (state: RootState) => state.entities.pots.byId,
  (allIds, byId) => allIds.map(id => byId[id])
);

export const selectPotById = createCachedSelector(
  (state: RootState, id: PotEntity['id']) => id,
  (state: RootState, id: PotEntity['id']) => state.entities.pots.byId,
  (id, byId) => byId[id]
)((state: RootState, id: PotEntity['id']) => id);

export const selectPotEndsAtById = createCachedSelector(
  (state: RootState, id: PotEntity['id']) => state.entities.pots.strategyById[id]?.endsAt,
  endsAt => (endsAt ? dateFromTimestamp(endsAt) : null)
)((state: RootState, id: PotEntity['id']) => id);

export const selectPotDrawLengthById = createCachedSelector(
  (state: RootState, id: PotEntity['id']) => state.entities.pots.strategyById[id]?.drawLength,
  duration => duration || 0
)((state: RootState, id: PotEntity['id']) => id);

export const selectPotNoOfWinnersById = createCachedSelector(
  (state: RootState, id: PotEntity['id']) => state.entities.pots.strategyById[id]?.numberOfWinners,
  numberOfWinners => (numberOfWinners === undefined ? null : numberOfWinners)
)((state: RootState, id: PotEntity['id']) => id);

export const selectPotExistsById = createCachedSelector(
  (state: RootState, id: PotEntity['id']) => id,
  (state: RootState, id: PotEntity['id']) => state.entities.pots.allIds,
  (id, allIds) => allIds.includes(id)
)((state: RootState, id: PotEntity['id']) => id);

export const selectPotsByNetworkId = createCachedSelector(
  (state: RootState, networkId: NetworkEntity['id']) => networkId,
  (state: RootState) => state.entities.pots.byNetworkId,
  (state: RootState) => state.entities.pots.byId,
  (networkId, byNetworkId, byId) => byNetworkId[networkId].map(potId => byId[potId])
)((state: RootState, networkId: NetworkEntity['id']) => networkId);

export const selectPotAwards = createCachedSelector(
  (state: RootState, id: PotEntity['id']) => state.entities.pots.strategyById[id]?.awards,
  awards => awards || []
)((state: RootState, id: PotEntity['id']) => id);

export const selectPotHasLock = createCachedSelector(
  (state: RootState, id: PotEntity['id']) => state.entities.pots.dataById[id]?.unstakeableAfter,
  unstakeableAfter => !unstakeableAfter
)((state: RootState, id: PotEntity['id']) => id);

export const selectPotUnlocksAfter = createCachedSelector(
  (state: RootState, id: PotEntity['id']) => state.entities.pots.dataById[id]?.unstakeableAfter,
  unstakeableAfter => (unstakeableAfter ? new Date(unstakeableAfter * 1000) : null)
)((state: RootState, id: PotEntity['id']) => id);

export const selectPotIsLocked = createCachedSelector(
  (state: RootState, id: PotEntity['id']) => state.entities.pots.dataById[id]?.unstakeableAfter,
  unstakeableAfter => !!unstakeableAfter && isAfter(new Date(unstakeableAfter * 1000), new Date())
)((state: RootState, id: PotEntity['id']) => id);

export const selectPotNumberOfWinners = createCachedSelector(
  (state: RootState, id: PotEntity['id']) => id,
  (state: RootState) => state.entities.pots.strategyById,
  (id, strategyById) => strategyById[id]?.numberOfWinners || 0
)((state: RootState, id: PotEntity['id']) => id);

export const selectPotTicketTotalSupply = createCachedSelector(
  (state: RootState, id: PotEntity['id']) => state.entities.pots.ticketById[id]?.totalSupply,
  totalSupply => (totalSupply ? new BigNumber(totalSupply) : null)
)((state: RootState, id: PotEntity['id']) => id);

export type PotStrategyAwardEntityWithToken = PotStrategyAwardEntity & {
  token: TokenEntity;
  tokensPerAward: BigNumber[];
};
export type PotPrizes = {
  total: number;
  prizes: PotStrategyAwardEntityWithToken[];
};
export const selectPotPrizesWithTokens = (state: RootState, id: PotEntity['id']): PotPrizes => {
  const pot = selectPotById(state, id);
  const awards = selectPotAwards(state, id);
  const totalPrizes = selectPotNumberOfWinners(state, id);

  const awardsWithTokens = awards
    .map((award): PotStrategyAwardEntityWithToken | null => {
      const token = selectTokenByNetworkAddress(state, pot.network, award.tokenAddress);
      if (token) {
        return {
          ...award,
          token,
          tokensPerAward: award.numberOfTokensPerAward.map(value =>
            toTokenDecimals(value, token.decimals)
          ),
        };
      }

      return null;
    })
    .filter(v => !!v) as PotStrategyAwardEntityWithToken[];

  return {
    total: totalPrizes,
    prizes: awardsWithTokens,
  };
};
