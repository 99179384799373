import { IConfigApi } from './config/types';
import { IWalletConnectionApi, WalletConnectionOptions } from './wallet-connection/types';
import { createFactoryWithCacheByNetwork } from '../../../utils/factory';
import { sample } from 'lodash';
import { INftApi } from './nft/types';
import { ContractDataApi } from './contract-data/contract-data';

let config: IConfigApi | null = null;
export async function getConfigApi(): Promise<IConfigApi> {
  if (!config) {
    const { ConfigApi } = await import('./config/config');
    config = new ConfigApi();
  }

  return config;
}

export const getWeb3ForNetwork = createFactoryWithCacheByNetwork(async network => {
  const { default: Web3 } = await import('web3');
  const rpc = sample(network.rpc);

  if (rpc) {
    return new Web3(rpc);
  }

  throw new Error(`No RPCs defined for ${network.id}`);
});

let walletConnection: IWalletConnectionApi | null = null;
export async function createWalletConnectionApi(
  options: WalletConnectionOptions
): Promise<IWalletConnectionApi> {
  if (walletConnection) {
    return walletConnection;
  }

  const { WalletConnectionApi } = await import('./wallet-connection/wallet-connection');
  walletConnection = new WalletConnectionApi(options);
  return walletConnection;
}

export async function getWalletConnectionApi(): Promise<IWalletConnectionApi> {
  if (!walletConnection) {
    throw new Error('Wallet connect instance not created yet.');
  }

  return walletConnection;
}

let nft: INftApi | null = null;
export async function getNftApi(): Promise<INftApi> {
  if (!nft) {
    const { NftApi } = await import('./nft/nft');
    nft = new NftApi();
  }

  return nft;
}

export const getContractDataApiForNetwork = createFactoryWithCacheByNetwork(async network => {
  const web3 = await getWeb3ForNetwork(network);
  return new ContractDataApi(web3, network);
});
