import { createAsyncThunk } from '@reduxjs/toolkit';
import { NetworkEntity } from '../entities/network';
import { TokenEntity } from '../entities/token';
import { BigNumber } from 'bignumber.js';
import { selectTokenByNetworkAddress } from '../selectors/tokens';
import { getWeb3ForNetwork } from '../api';
import { selectNetworkById } from '../selectors/networks';
import { ERC721EnumerableAbi } from '../../config/abi';
import { MultiCall, ShapeWithLabel } from 'eth-multicall';
import { BIG_ZERO } from '../../../utils/bignumber';
import { selectAllPots } from '../selectors/pots';
import { RootState } from '../store-types';

export type BalanceFetchAllArgs = {
  walletAddress: string;
};
export const balanceFetchAll = createAsyncThunk<void, BalanceFetchAllArgs, { state: RootState }>(
  'balance/fetchAll',
  async ({ walletAddress }, { getState, dispatch }) => {
    const state = getState();
    const pots = selectAllPots(state);

    for (const pot of pots) {
      dispatch(
        balanceFetch({
          networkId: pot.network,
          tokenAddress: pot.potAddress,
          walletAddress,
        })
      );

      dispatch(
        balanceFetch({
          networkId: pot.network,
          tokenAddress: pot.depositTokenAddress,
          walletAddress,
        })
      );
    }
  }
);

export type BalanceFetchArgs = {
  networkId: NetworkEntity['id'];
  tokenAddress: TokenEntity['address'];
  walletAddress: string;
};
export type BalanceFetchPayload = {
  balance: number;
  byId: Record<string, number>;
};
export const balanceFetch = createAsyncThunk<
  BalanceFetchPayload,
  BalanceFetchArgs,
  { state: RootState }
>('balance/fetch', async ({ networkId, tokenAddress, walletAddress }, { getState }) => {
  const state = getState();
  const network = selectNetworkById(state, networkId);
  const token = selectTokenByNetworkAddress(state, networkId, tokenAddress);

  if (token.type === 'erc721') {
    return balanceFetchErc721(token, network, walletAddress);
  }

  throw new Error(`${token.type} not supported`);
});

async function balanceFetchErc721(
  token: TokenEntity,
  network: NetworkEntity,
  walletAddress: string
): Promise<BalanceFetchPayload> {
  const web3 = await getWeb3ForNetwork(network);
  const contract = new web3.eth.Contract(ERC721EnumerableAbi, token.address);
  const balance = new BigNumber(await contract.methods.balanceOf(walletAddress).call());

  if (balance.gt(BIG_ZERO)) {
    const multicall = new MultiCall(web3, network.multicallAddress);
    const calls: ShapeWithLabel[] = Array.from({ length: balance.toNumber() }, (x, i) => ({
      id: contract.methods.tokenOfOwnerByIndex(walletAddress, i),
    }));
    const [results] = (await multicall.all([calls])) as { id: string }[][];

    return {
      balance: balance.toNumber(),
      byId: Object.fromEntries(results.map(result => [result.id, 1])),
    };
  }

  return {
    balance: balance.toNumber(),
    byId: {},
  };
}
