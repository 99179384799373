import { intervalToDuration } from 'date-fns';
import { zeroPad } from './format';

export function dateFromTimestamp(seconds: string | number): Date {
  return new Date((typeof seconds === 'string' ? parseInt(seconds) : seconds) * 1000);
}

export function formatTimeUntil(when: Date): string {
  const parts: (keyof Duration)[] = ['years', 'months', 'days', 'hours', 'minutes', 'seconds'];
  const duration = intervalToDuration({ start: new Date(), end: when });

  for (let i = 0; i < parts.length - 3; ++i) {
    if (duration[parts[i]]) {
      return parts
        .slice(i, i + 3)
        .map(part => `${zeroPad(duration[part])}${part[0]}`)
        .join(' ');
    }
  }

  return `${zeroPad(duration.hours)}h ${zeroPad(duration.minutes)}m ${zeroPad(duration.seconds)}s`;
}
