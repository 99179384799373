import { createSlice } from '@reduxjs/toolkit';
import { chanceFetch, chanceFetchAllForNetwork } from '../actions/chance';
import { PotEntity } from '../entities/pot';

export type ChanceState = {
  byWallet: {
    [address: string]: {
      byPot: {
        [pot: PotEntity['id']]: string;
      };
    };
  };
};

const initialState: ChanceState = {
  byWallet: {},
};

const chanceSlice = createSlice({
  name: 'chance',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(chanceFetch.fulfilled, (sliceState, action) => {
      const { potId, walletAddress } = action.meta.arg;
      const wallet = walletAddress.toLowerCase();

      if (!(wallet in sliceState.byWallet)) {
        sliceState.byWallet[wallet] = {
          byPot: {},
        };
      }

      sliceState.byWallet[wallet].byPot[potId] = action.payload.chance;
    });
    builder.addCase(chanceFetchAllForNetwork.fulfilled, (sliceState, action) => {
      const { walletAddress } = action.meta.arg;
      const wallet = walletAddress.toLowerCase();

      if (!(wallet in sliceState.byWallet)) {
        sliceState.byWallet[wallet] = {
          byPot: {},
        };
      }

      for (const potChance of action.payload.chances) {
        sliceState.byWallet[wallet].byPot[potChance.id] = potChance.chance;
      }
    });
  },
});

export const chanceReducer = chanceSlice.reducer;
