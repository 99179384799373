class Theme {
  public readonly breakpoints = {
    xs: 0,
    sm: 768,
    md: 960,
    lg: 1280,
  };

  public readonly containers = {
    xs: 440,
    sm: 768,
    md: 768,
    lg: 1280,
  };
}

export const theme = new Theme();
