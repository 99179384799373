import {
  ActionReducerMapBuilder,
  AsyncThunk,
  createSlice,
  SerializedError,
} from '@reduxjs/toolkit';
import { AsyncThunkConfig, LoaderState } from './data-loader-types';
import { isString } from 'lodash';
import { networksInit } from '../actions/networks';

const dataLoaderStateInit: LoaderState = {
  alreadyLoadedOnce: false,
  status: 'init',
  error: null,
};

const dataLoaderStateFulfilled: LoaderState = {
  alreadyLoadedOnce: true,
  status: 'fulfilled',
  error: null,
};

const dataLoaderStatePending: LoaderState = {
  alreadyLoadedOnce: false,
  status: 'pending',
  error: null,
};

export type DataLoaderState = {
  global: {
    networks: LoaderState;
    pots: LoaderState;
    tokens: LoaderState;
  };
};

const initialState: DataLoaderState = {
  global: {
    networks: dataLoaderStateInit,
    pots: dataLoaderStateInit,
    tokens: dataLoaderStateInit,
  },
};

const dataLoaderSlice = createSlice({
  name: 'loader',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    addGlobalAsyncThunkActions(builder, networksInit, 'networks');
  },
});

function addGlobalAsyncThunkActions(
  builder: ActionReducerMapBuilder<DataLoaderState>,
  action: AsyncThunk<any, any, AsyncThunkConfig> | AsyncThunk<any, any, {}>,
  stateKey: keyof DataLoaderState['global']
) {
  builder.addCase(action.pending, sliceState => {
    sliceState.global[stateKey] = {
      ...dataLoaderStatePending,
      alreadyLoadedOnce: sliceState.global[stateKey].alreadyLoadedOnce,
    };
  });
  builder.addCase(action.rejected, (sliceState, action) => {
    sliceState.global[stateKey] = {
      status: 'rejected',
      error: getMessage(action.error),
      alreadyLoadedOnce: sliceState.global[stateKey].alreadyLoadedOnce,
    };
  });
  builder.addCase(action.fulfilled, sliceState => {
    sliceState.global[stateKey] = dataLoaderStateFulfilled;
  });
}

function getMessage(error: SerializedError) {
  return isString(error) ? error : (error?.message || error?.name || error?.code) + '';
}

export const dataLoaderReducer = dataLoaderSlice.reducer;
