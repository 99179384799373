import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NetworkEntity } from '../entities/network';
import { flag_walletAddressOverride } from '../../../utils/flags';

export type WalletState = {
  address: string | null;
  networkId: NetworkEntity['id'] | null;
};

const initialState: WalletState = {
  address: null,
  networkId: null,
};

const walletSlice = createSlice({
  name: 'wallet',
  initialState: initialState,
  reducers: {
    connected(
      sliceState,
      action: PayloadAction<{ networkId: NetworkEntity['id']; address: string }>
    ) {
      sliceState.networkId = action.payload.networkId;
      sliceState.address = flag_walletAddressOverride(action.payload.address);
    },
    unsupported(sliceState, action: PayloadAction<{ address: string }>) {
      sliceState.networkId = null;
      sliceState.address = flag_walletAddressOverride(action.payload.address);
    },
    disconnected(sliceState) {
      sliceState.networkId = null;
      sliceState.address = null;
    },
  },
});

export const walletReducer = walletSlice.reducer;
export const {
  connected: walletConnected,
  unsupported: walletUnsupported,
  disconnected: walletDisconnected,
} = walletSlice.actions;
