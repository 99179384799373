import { createSlice } from '@reduxjs/toolkit';
import { NetworkEntity } from '../entities/network';
import { networksInit } from '../actions/networks';

export type NetworkState = {
  byId: Record<NetworkEntity['id'], NetworkEntity>;
  allIds: NetworkEntity['id'][];
};

const initialState: NetworkState = {
  byId: {},
  allIds: [],
};

const networkSlice = createSlice({
  name: 'network',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(networksInit.fulfilled, (sliceState, action) => {
      for (const network of action.payload) {
        if (!(network.id in sliceState.byId)) {
          sliceState.byId[network.id] = network;
          sliceState.allIds.push(network.id);
        }
      }
    });
  },
});

export const networkReducer = networkSlice.reducer;
