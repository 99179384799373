import createCachedSelector from 're-reselect';
import { TokenEntity } from '../entities/token';
import { NetworkEntity } from '../entities/network';
import { RootState } from '../store-types';

export const selectTokensForNetwork = createCachedSelector(
  (state: RootState, networkId: NetworkEntity['id']) => networkId,
  (state: RootState) => state.entities.tokens.byNetwork,
  (networkId, byNetwork) => byNetwork[networkId]
)((state: RootState, networkId: NetworkEntity['id']) => networkId);

export const selectTokenByNetworkAddress = createCachedSelector(
  (state: RootState, networkId: NetworkEntity['id'], address: TokenEntity['address']) =>
    address.toLowerCase(),
  (state: RootState, networkId: NetworkEntity['id'], address: TokenEntity['address']) =>
    selectTokensForNetwork(state, networkId),
  (address, networkTokens) => networkTokens?.byAddress[address]
)(
  (state: RootState, networkId: NetworkEntity['id'], address: TokenEntity['address']) =>
    `${networkId}-${address.toLowerCase()}`
);
