import { memo } from 'react';
import styled from '@emotion/styled';
import { FluidInner } from '../../components/Inner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMedium, faTelegram, faTwitter } from '@fortawesome/free-brands-svg-icons';

const TextLink = styled.a`
  color: #fff;
  display: block;
  text-decoration: none;
`;

const IconLink = styled(TextLink)`
  font-size: 24px;
`;

const Links = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const LinksHolder = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
`;

type FooterProps = {
  className?: string;
};
export const Footer = styled(
  memo<FooterProps>(function Footer({ className }) {
    return (
      <footer className={className}>
        <FluidInner>
          <LinksHolder>
            <Links>
              <IconLink href="https://twitter.com/ziggyverse" target="_blank" title="Twitter">
                <FontAwesomeIcon icon={faTwitter} />
              </IconLink>
              <IconLink href="https://t.me/ziggyverse" target="_blank" title="Telegram">
                <FontAwesomeIcon icon={faTelegram} />
              </IconLink>
              <IconLink href="https://medium.com/ziggyverse" target="_blank" title="Blog">
                <FontAwesomeIcon icon={faMedium} />
              </IconLink>
              <TextLink href="https://www.ziggyverse.com" target="_blank">
                Website
              </TextLink>
              <TextLink href="https://docs.ziggverse.com" target="_blank">
                Docs
              </TextLink>
            </Links>
          </LinksHolder>
        </FluidInner>
      </footer>
    );
  })
)`
  background: rgba(0, 0, 0, 0.5);
  padding-top: 16px;
  padding-bottom: 16px;
`;
