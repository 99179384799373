import { NetworkEntity } from '../features/data/entities/network';

export function createFactoryWithCacheByNetwork<T>(
  factoryFn: (network: NetworkEntity) => Promise<T>
): (chain: NetworkEntity) => Promise<T> {
  const cacheByNetworkId: { [networkId: NetworkEntity['id']]: Promise<T> } = {};

  return (network: NetworkEntity): Promise<T> => {
    if (cacheByNetworkId[network.id] === undefined) {
      cacheByNetworkId[network.id] = factoryFn(network);
    }
    return cacheByNetworkId[network.id];
  };
}
