import { createSlice } from '@reduxjs/toolkit';
import { TokenEntity } from '../entities/token';
import { tokensInit } from '../actions/tokens';
import { NetworkEntity } from '../entities/network';

export type TokenState = {
  byNetwork: Record<
    NetworkEntity['id'],
    {
      byAddress: Record<TokenEntity['address'], TokenEntity>;
      allAddresses: TokenEntity['address'][];
    }
  >;
};

const initialState: TokenState = {
  byNetwork: {},
};

const tokensSlice = createSlice({
  name: 'tokens',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(tokensInit.fulfilled, (sliceState, action) => {
      for (const [network, tokens] of Object.entries(action.payload)) {
        if (!(network in sliceState.byNetwork)) {
          sliceState.byNetwork[network] = {
            byAddress: {},
            allAddresses: [],
          };
        }

        for (const token of tokens) {
          const address = token.address.toLowerCase();
          if (!(address in sliceState.byNetwork[network].byAddress)) {
            sliceState.byNetwork[network].byAddress[address] = {
              ...token,
              network,
            };
            sliceState.byNetwork[network].allAddresses.push(address);
          } else {
            console.error(`Duplicate token address ${address} for ${network}`);
          }
        }
      }
    });
  },
});

export const tokensReducer = tokensSlice.reducer;
