import { memo, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../features/data/hooks';
import { walletConnect } from '../../features/data/actions/wallet';
import { selectWalletIsConnected } from '../../features/data/selectors/wallet';
import { ReactComponent as SignInSvg } from '../../images/icons/sign-in.svg';
import styled from '@emotion/styled';
import { ButtonWithIcon } from '../Button/Button';

const SignInIcon = styled(SignInSvg)`
  fill: #fff;
  width: 16px;
  height: 16px;
`;

export const WalletConnection = memo(function WalletConnection() {
  const dispatch = useAppDispatch();
  const connected = useAppSelector(selectWalletIsConnected);
  const handleConnect = useCallback(() => {
    dispatch(walletConnect());
  }, [dispatch]);

  if (connected) {
    return null;
  }

  return (
    <ButtonWithIcon onClick={handleConnect} Icon={SignInIcon}>
      Connect
    </ButtonWithIcon>
  );
});
