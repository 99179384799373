import { hexToNumber, isHexStrict } from 'web3-utils';
import { padStart } from 'lodash';

export function maybeHexToNumber(input: any): number {
  if (typeof input === 'number') {
    return input;
  }

  if (typeof input === 'string') {
    return isHexStrict(input) ? hexToNumber(input) : Number(input);
  }

  throw new Error(`${typeof input} "${input}" is not valid hex or number.`);
}

export function zeroPad(value: number | undefined): string {
  return padStart((value || 0).toString(), 2, '0');
}
