import { createAsyncThunk } from '@reduxjs/toolkit';
import { createWalletConnectionApi, getWalletConnectionApi } from '../api';
import { NetworkEntity } from '../entities/network';
import { walletConnected, walletDisconnected, walletUnsupported } from '../reducers/wallet';
import { selectAllNetworks } from '../selectors/networks';
import { RootState } from '../store-types';

export const walletInit = createAsyncThunk<void, void, { state: RootState }>(
  'wallet/init',
  async (_, { dispatch, getState }) => {
    const state = await getState();
    const networks = selectAllNetworks(state);

    await createWalletConnectionApi({
      networks: Object.values(networks),
      onConnect: (networkId, address) => {
        dispatch(walletConnected({ networkId, address }));
      },
      onConnectUnsupported: (chainId, address) => {
        dispatch(walletUnsupported({ address }));
      },
      onDisconnect: () => {
        dispatch(walletDisconnected());
      },
    });

    setTimeout(() => {
      dispatch(walletReconnect());
    });
  }
);

export const walletReconnect = createAsyncThunk('wallet/reconnect', async () => {
  const api = await getWalletConnectionApi();
  await api.tryToAutoReconnect();
});

export const walletConnect = createAsyncThunk('wallet/connect', async () => {
  const api = await getWalletConnectionApi();
  await api.askUserToConnectIfNeeded();
});

export const walletDisconnect = createAsyncThunk('wallet/disconnect', async () => {
  const api = await getWalletConnectionApi();
  await api.disconnect();
});

export const walletChangeNetwork = createAsyncThunk<void, { networkId: NetworkEntity['id'] }>(
  'wallet/changeNetwork',
  async ({ networkId }) => {
    const api = await getWalletConnectionApi();
    await api.askUserForNetworkChange(networkId);
  }
);
