import { AbiItem } from 'web3-utils';
import ERC721EnumerableJson from './ERC721Enumerable.json';
import ERC1155Json from './ERC1155.json';
import ERC20StrategyJson from './ERC20Strategy.json';
import ZiggyverseStakerPotERC721Json from './ZiggyverseStakerPotERC721.json';
import NonTransferableTicketJson from './NonTransferableTicket.json';

export const ERC721EnumerableAbi = ERC721EnumerableJson as AbiItem[];
export const ERC1155Abi = ERC1155Json as AbiItem[];
export const ERC20StrategyAbi = ERC20StrategyJson as AbiItem[];
export const ZiggyverseStakerPotERC721Abi = ZiggyverseStakerPotERC721Json as AbiItem[];
export const NonTransferableTicketAbi = NonTransferableTicketJson as AbiItem[];
