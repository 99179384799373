import { createSlice } from '@reduxjs/toolkit';
import { TokenEntity } from '../entities/token';
import { NetworkEntity } from '../entities/network';
import { NftEntity } from '../entities/nft';
import { nftsFetchMetadata } from '../actions/nfts';
import missingImageUrl from '../../../images/nfts/error.png';

export type NftState = {
  byNetwork: Record<
    NetworkEntity['id'],
    {
      byAddress: Record<
        TokenEntity['address'],
        {
          byId: Record<string, NftEntity>;
          allIds: string[];
        }
      >;
      allAddresses: TokenEntity['address'][];
    }
  >;
};

const initialState: NftState = {
  byNetwork: {},
};

const nftsSlice = createSlice({
  name: 'nfts',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(nftsFetchMetadata.fulfilled, (sliceState, action) => {
      const { networkId, address, metadata } = action.payload;
      const lowercaseAddress = address.toLowerCase();

      if (!(networkId in sliceState.byNetwork)) {
        sliceState.byNetwork[networkId] = {
          byAddress: {},
          allAddresses: [],
        };
      }

      if (!(lowercaseAddress in sliceState.byNetwork[networkId].byAddress)) {
        sliceState.byNetwork[networkId].byAddress[lowercaseAddress] = {
          byId: {},
          allIds: [],
        };
        sliceState.byNetwork[networkId].allAddresses = Object.keys(
          sliceState.byNetwork[networkId].byAddress
        );
      }

      for (const meta of metadata) {
        if (!(meta.id in sliceState.byNetwork[networkId].byAddress[lowercaseAddress].byId)) {
          sliceState.byNetwork[networkId].byAddress[lowercaseAddress].byId[meta.id] = {
            id: meta.id,
            name: meta.name || `#${meta.id}`,
            networkId: networkId,
            address: address,
            image: meta.image || missingImageUrl,
          };
          sliceState.byNetwork[networkId].byAddress[lowercaseAddress].allIds.push(meta.id);
        } else {
          console.error(`Duplicate metadata id ${meta.id} for ${address} on ${networkId}`);
        }
      }
    });
  },
});

export const nftsReducer = nftsSlice.reducer;
