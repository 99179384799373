import createCachedSelector from 're-reselect';
import { NetworkEntity } from '../entities/network';
import { RootState } from '../store-types';

export const selectWalletIsConnected = (state: RootState) => state.user.wallet.address !== null;
export const selectWalletIsConnectedToNetwork = createCachedSelector(
  (state: RootState, networkId: NetworkEntity['id']) => networkId,
  (state: RootState, networkId: NetworkEntity['id']) => state.user.wallet.networkId,
  (state: RootState, networkId: NetworkEntity['id']) => selectWalletIsConnected(state),
  (wantedNetwork, connectedNetwork, connected) => connected && wantedNetwork === connectedNetwork
)((state: RootState, networkId: NetworkEntity['id']) => networkId);

export const selectWalletAddressIfKnown = (state: RootState) => state.user.wallet.address;

export const selectWalletAddress = (state: RootState) => {
  if (state.user.wallet.address) {
    return state.user.wallet.address;
  }

  throw new Error(`No wallet connected`);
};
