import { memo } from 'react';
import styled from '@emotion/styled';
import landscape1366 from '../../images/background/landscape-1366w.png';
import landscape1440 from '../../images/background/landscape-1440w.png';
import landscape1536 from '../../images/background/landscape-1536w.png';
import landscape1600 from '../../images/background/landscape-1600w.png';
import landscape1920 from '../../images/background/landscape-1920w.png';
import landscape2560 from '../../images/background/landscape-2560w.png';
import landscape3840 from '../../images/background/landscape-3840w.png';
import portrait360 from '../../images/background/portrait-360w.png';
import portrait414 from '../../images/background/portrait-414w.png';
import portrait720 from '../../images/background/portrait-720w.png';
import portrait828 from '../../images/background/portrait-828w.png';
import { keyframes } from '@emotion/react';

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

type BackgroundPictureProps = {
  className?: string;
};
const BackgroundPicture = memo<BackgroundPictureProps>(function BackgroundPicture({ className }) {
  return (
    <picture className={className}>
      <source
        media="(max-width: 799px)"
        sizes="100vw"
        srcSet={`${portrait360} 360w, ${portrait414} 414w, ${portrait720} 720w, ${portrait828} 828w`}
      />
      <source
        media="(min-width: 800px)"
        sizes="100vw"
        srcSet={`${landscape1366} 1366w, ${landscape1440} 1440w, ${landscape1536} 1536w, ${landscape1600} 1600w, ${landscape1920} 1920w, ${landscape2560} 2560w, ${landscape3840} 3840w`}
      />
      <Image
        src={landscape1366}
        sizes="100vw"
        alt=""
        role="presentation"
        aria-hidden="true"
        srcSet={`${landscape1366} 1366w, ${landscape1440} 1440w, ${landscape1536} 1536w, ${landscape1600} 1600w, ${landscape1920} 1920w, ${landscape2560} 2560w, ${landscape3840} 3840w`}
      />
    </picture>
  );
});

const Holder = styled.div`
  position: fixed;
  z-index: -1;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Layer = styled(BackgroundPicture)`
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const orbit = keyframes`
	0% {
		transform: rotate(0deg) translate(-5px) rotate(0deg);
    opacity: 0.1;
	}
  
  50% {
    opacity: 0.25;
  }

	100% {
		transform: rotate(360deg) translate(-5px) rotate(-360deg);
    opacity: 0.1;
	}
`;

const LayerOne = styled(Layer)`
  opacity: 0.25;
  animation: ${orbit} 4s linear infinite;
`;

const LayerTwo = styled(Layer)`
  opacity: 0.1;
  animation: ${orbit} 4s linear infinite 0.25s;
`;

export const AnimatedBackground = memo(function AnimatedBackground() {
  return (
    <Holder>
      <Layer />
      <LayerOne />
      <LayerTwo />
    </Holder>
  );
});
