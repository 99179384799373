import { createAsyncThunk } from '@reduxjs/toolkit';
import { NetworkEntity } from '../entities/network';
import { getWeb3ForNetwork } from '../api';
import { selectNetworkById } from '../selectors/networks';
import { NonTransferableTicketAbi } from '../../config/abi';
import { MultiCall } from 'eth-multicall';
import { selectPotById, selectPotsByNetworkId } from '../selectors/pots';
import { RootState } from '../store-types';
import { PotEntity } from '../entities/pot';

export type ChanceFetchAllArgs = {
  networkId: NetworkEntity['id'];
  walletAddress: string;
};
export type ChanceFetchAllPayload = {
  chances: { id: PotEntity['id']; chance: string }[];
};
export const chanceFetchAllForNetwork = createAsyncThunk<
  ChanceFetchAllPayload,
  ChanceFetchAllArgs,
  { state: RootState }
>('chance/fetchAll', async ({ networkId, walletAddress }, { getState }) => {
  const state = getState();
  const pots = selectPotsByNetworkId(state, networkId);
  if (pots.length === 0) {
    return {
      chances: [],
    };
  }

  const network = selectNetworkById(state, networkId);
  const web3 = await getWeb3ForNetwork(network);
  const multicall = new MultiCall(web3, network.multicallAddress);

  const calls = pots.map(pot => {
    const contract = new web3.eth.Contract(NonTransferableTicketAbi, pot.ticketAddress);
    return {
      id: pot.id,
      chance: contract.methods.chanceOf(walletAddress),
    };
  });

  const results = await multicall.all([calls]);
  const chanceResults: { id: PotEntity['id']; chance: string }[] = results[0];

  return {
    chances: chanceResults,
  };
});

export type ChanceFetchArgs = {
  potId: PotEntity['id'];
  walletAddress: string;
};
export type ChanceFetchPayload = {
  chance: string;
};
export const chanceFetch = createAsyncThunk<
  ChanceFetchPayload,
  ChanceFetchArgs,
  { state: RootState }
>('chance/fetch', async ({ potId, walletAddress }, { getState }) => {
  const state = getState();
  const pot = selectPotById(state, potId);
  const network = selectNetworkById(state, pot.network);
  const web3 = await getWeb3ForNetwork(network);
  const contract = new web3.eth.Contract(NonTransferableTicketAbi, pot.ticketAddress);
  const chance = await contract.methods.chanceOf(walletAddress).call();

  return {
    chance: chance || '0',
  };
});
