import { PotDataEntity, PotEntity, PotStrategyEntity, PotTicketEntity } from '../../entities/pot';

export type PotContractData = {
  id: PotEntity['id'];
  strategy: PotStrategyEntity;
  data: PotDataEntity;
  ticket: PotTicketEntity;
};

export enum RawStrategyAwardContractDataKeys {
  numberOfWinners,
  collections,
  numberOfAwards,
  numberOfTokensPerAwards,
  vaults,
  available,
}

export type RawStrategyAwardContractData = {
  [RawStrategyAwardContractDataKeys.numberOfWinners]: string;
  [RawStrategyAwardContractDataKeys.collections]: string[];
  [RawStrategyAwardContractDataKeys.numberOfAwards]: string[];
  [RawStrategyAwardContractDataKeys.numberOfTokensPerAwards]: string[][];
  [RawStrategyAwardContractDataKeys.vaults]: string[];
  [RawStrategyAwardContractDataKeys.available]: string[];
};

export type RawStrategyContractData = {
  id: string;
  startedAt: string;
  endsAt: string;
  drawLength: string;
  awards: RawStrategyAwardContractData;
};

export type RawPotContractData = {
  id: string;
  totalSupply: string;
  unstakeableAfter: string;
};

export type RawTicketContractData = {
  id: string;
  totalSupply: string;
};

export interface IContractDataApi {
  fetchAllPotsData(pots: PotEntity[]): Promise<PotContractData[]>;
}
