import { createAsyncThunk } from '@reduxjs/toolkit';
import { getConfigApi } from '../api';
import { NetworkEntity } from '../entities/network';
import { TokenConfig } from '../api/config/types';

export const tokensInit = createAsyncThunk<Record<NetworkEntity['id'], TokenConfig[]>>(
  'tokens/init',
  async () => {
    const api = await getConfigApi();
    return await api.getTokens();
  }
);
