import { createAsyncThunk } from '@reduxjs/toolkit';
import { getNftApi } from '../api';
import { NetworkEntity } from '../entities/network';
import { TokenEntity } from '../entities/token';
import { NftMeta } from '../api/nft/types';
import { selectNetworkById } from '../selectors/networks';
import { selectTokenByNetworkAddress } from '../selectors/tokens';
import { RootState } from '../store-types';

export type NftsFetchMetadataArgs = {
  address: TokenEntity['address'];
  ids: string[];
  networkId: NetworkEntity['id'];
};

export type NftsFetchMetadataPayload = {
  address: TokenEntity['address'];
  networkId: NetworkEntity['id'];
  metadata: NftMeta[];
};

export const nftsFetchMetadata = createAsyncThunk<
  NftsFetchMetadataPayload,
  NftsFetchMetadataArgs,
  { state: RootState }
>('nfts/fetchMetadata', async ({ address, ids, networkId }, { getState }) => {
  const api = await getNftApi();
  const state = getState();
  const network = selectNetworkById(state, networkId);
  const token = selectTokenByNetworkAddress(state, networkId, address);

  if (token.type !== 'erc721') {
    throw new Error(`${token.type} not supported`);
  }

  // TODO filter ids to only those we don't have metadata for

  const metadata = await api.getMetadata(token, ids, network);

  return {
    address,
    networkId,
    metadata,
  };
});
