import { createSlice } from '@reduxjs/toolkit';
import { NetworkEntity } from '../entities/network';
import { TokenEntity } from '../entities/token';
import { allowanceFetch } from '../actions/allowance';

export type AllowanceErc721 = {
  type: 'erc721';
  isApprovedForAll: boolean;
};

export type AllowanceType = AllowanceErc721;

export function isErc721Allowance(allowance: AllowanceType): allowance is AllowanceErc721 {
  return allowance.type === 'erc721';
}

export type AllowanceState = {
  byWallet: {
    [address: string]: {
      byNetwork: {
        [network: NetworkEntity['id']]: {
          byAddress: {
            [address: TokenEntity['address']]: {
              bySpender: Record<string, AllowanceType>;
            };
          };
        };
      };
    };
  };
};

const initialState: AllowanceState = {
  byWallet: {},
};

const allowanceSlice = createSlice({
  name: 'allowance',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(allowanceFetch.fulfilled, (sliceState, action) => {
      const { tokenAddress, networkId, walletAddress, spenderAddress } = action.meta.arg;
      const wallet = walletAddress.toLowerCase();
      const token = tokenAddress.toLowerCase();
      const spender = spenderAddress.toLowerCase();

      if (!(wallet in sliceState.byWallet)) {
        sliceState.byWallet[wallet] = {
          byNetwork: {},
        };
      }

      if (!(networkId in sliceState.byWallet[wallet].byNetwork)) {
        sliceState.byWallet[wallet].byNetwork[networkId] = {
          byAddress: {},
        };
      }

      if (!(token in sliceState.byWallet[wallet].byNetwork[networkId].byAddress)) {
        sliceState.byWallet[wallet].byNetwork[networkId].byAddress[token] = {
          bySpender: {},
        };
      }

      sliceState.byWallet[wallet].byNetwork[networkId].byAddress[token].bySpender[spender] =
        action.payload;
    });
  },
});

export const allowanceReducer = allowanceSlice.reducer;
