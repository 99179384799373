import styled from '@emotion/styled';
import { ButtonHTMLAttributes, ComponentType, DetailedHTMLProps, memo, SVGProps } from 'react';

export const Button = styled.button`
  background: #fbd336;
  margin: 0;
  color: #fff;
  border: none;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  border-radius: 8px;
  background: linear-gradient(to bottom, #54318d 0%, #6e43b1 50%, #54318d 100%);
  padding: 8px 16px;
  display: inline-flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  text-align: center;

  &:disabled {
    cursor: not-allowed;
    background: #311e4f;
    color: #807f7f;
  }
`;

const StyledIcon = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
`;

type HtmlButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;
export type ButtonWithIconProps = {
  Icon: ComponentType<SVGProps<SVGSVGElement>>;
} & HtmlButtonProps;
export const ButtonWithIcon = memo<ButtonWithIconProps>(function ({ children, Icon, ...rest }) {
  return (
    <Button {...rest}>
      {children}
      <StyledIcon as={Icon} />
    </Button>
  );
});
