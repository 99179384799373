import React, { memo, Suspense } from 'react';
import styled from '@emotion/styled';
import { Route, Routes } from 'react-router-dom';
import { Loader } from './Loader';
import { Header } from './Header';
import { Footer } from './Footer';
import { Data } from './Data';
import { AnimatedBackground } from './Background';

const HomePage = React.lazy(() => import(`../home`));
const StakePage = React.lazy(() => import(`../stake`));
const PotPage = React.lazy(() => import(`../pot`));
const SwapPage = React.lazy(() => import(`../swap`));

const Layout = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: minmax(0, 1fr);
  min-height: 100vh;
  width: 100%;
`;

const Main = styled.main`
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const App = memo(function App() {
  return (
    <>
      <Data />
      <AnimatedBackground />
      <Layout>
        <Header />
        <Main>
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="stake/:id" element={<PotPage />} />
              <Route path="stake" element={<StakePage />} />
              <Route path="swap" element={<SwapPage />} />
            </Routes>
          </Suspense>
        </Main>
        <Footer />
      </Layout>
    </>
  );
});
