import { NetworkEntity } from '../entities/network';
import { createSelector } from '@reduxjs/toolkit';
import createCachedSelector from 're-reselect';
import { RootState } from '../store-types';

export const selectNetworkIds = (state: RootState) => state.entities.network.allIds;

export const selectAllNetworks = createSelector(
  (state: RootState) => state.entities.network.allIds,
  (state: RootState) => state.entities.network.byId,
  (allIds, byId) => allIds.map(id => byId[id])
);

export const selectNetworkById = createCachedSelector(
  (state: RootState, networkId: NetworkEntity['id']) => networkId,
  (state: RootState) => state.entities.network.byId,
  (networkId, byId) => byId[networkId]
)((state: RootState, networkId: NetworkEntity['id']) => networkId);
