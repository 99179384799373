import { createAsyncThunk } from '@reduxjs/toolkit';
import { walletInit } from './wallet';
import { networksInit } from './networks';
import { tokensInit } from './tokens';
import { potsFetchContractDataByNetwork, potsInit } from './pots';
import { selectNetworkIds } from '../selectors/networks';
import { RootState } from '../store-types';

export const appInit = createAsyncThunk<void, void, { state: RootState }>(
  'app/init',
  async (_, { dispatch, getState }) => {
    const networkPromise = dispatch(networksInit());
    const tokenPromise = dispatch(tokensInit());
    const potPromise = dispatch(potsInit());

    setTimeout(async () => {
      await networkPromise;
      dispatch(walletInit());
    });

    await Promise.all([networkPromise, tokenPromise, potPromise]);

    const networkIds = selectNetworkIds(getState());
    const potContractDataPromises = networkIds.map(networkId =>
      dispatch(potsFetchContractDataByNetwork({ networkId }))
    );

    await Promise.all(potContractDataPromises);
  }
);
