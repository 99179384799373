import { memo } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { FluidInner } from '../../components/Inner';
import { WalletConnection } from '../../components/WalletConnection';
import logoLandscape from '../../images/logo/landscape.svg';
import logoSquare from '../../images/logo/square.svg';

const NavBar = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

const NavLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  display: block;
`;

const NavLargeLink = styled(NavLink)`
  font-size: 24px;
`;

const NavWallet = styled.div`
  margin-left: auto;
`;

const LogoImg = styled.img`
  display: block;
  width: 30px;

  @media (min-width: 360px) {
    width: 180px;
  }
`;

const Logo = memo(function () {
  return (
    <picture>
      <source
        type="image/svg+xml"
        media="(max-width: 359px)"
        sizes="30px"
        srcSet={`${logoSquare} 30w`}
      />
      <source
        type="image/svg+xml"
        media="(min-width: 360px)"
        sizes="180px"
        srcSet={`${logoLandscape} 180w`}
      />
      <LogoImg src={logoLandscape} sizes="180px" alt="Ziggyverse" />
    </picture>
  );
});

type HeaderProps = {
  className?: string;
};
export const Header = styled(
  memo<HeaderProps>(function Header({ className }) {
    return (
      <header className={className}>
        <FluidInner>
          <NavBar>
            <NavLargeLink to="/">
              <Logo />
            </NavLargeLink>
            <NavWallet>
              <WalletConnection />
            </NavWallet>
          </NavBar>
        </FluidInner>
      </header>
    );
  })
)`
  background: rgba(0, 0, 0, 0.5);
  padding-top: 16px;
  padding-bottom: 16px;
`;
