import {
  IContractDataApi,
  PotContractData,
  RawPotContractData,
  RawStrategyAwardContractDataKeys,
  RawStrategyContractData,
  RawTicketContractData,
} from './types';
import { NetworkEntity } from '../../entities/network';
import Web3 from 'web3';
import {
  PotDataEntity,
  PotEntity,
  PotStrategyAwardEntity,
  PotStrategyEntity,
  PotTicketEntity,
} from '../../entities/pot';
import { MultiCall, ShapeWithLabel } from 'eth-multicall';
import {
  ERC20StrategyAbi,
  NonTransferableTicketAbi,
  ZiggyverseStakerPotERC721Abi,
} from '../../../config/abi';

export class ContractDataApi implements IContractDataApi {
  constructor(protected web3: Web3, protected network: NetworkEntity) {}

  public async fetchAllPotsData(pots: PotEntity[]): Promise<PotContractData[]> {
    const multicall = new MultiCall(this.web3, this.network.multicallAddress);
    const potCalls: ShapeWithLabel[] = pots.map(pot => {
      const contract = new this.web3.eth.Contract(ZiggyverseStakerPotERC721Abi, pot.potAddress);
      return {
        id: pot.id,
        totalSupply: contract.methods.totalSupply(),
        unstakeableAfter: contract.methods.unstakeableAfter(),
      };
    });
    const strategyCalls: ShapeWithLabel[] = pots.map(pot => {
      const contract = new this.web3.eth.Contract(ERC20StrategyAbi, pot.strategyAddress);
      return {
        id: pot.id,
        startedAt: contract.methods.prizePeriodStartedAt(),
        endsAt: contract.methods.prizePeriodEndAt(),
        drawLength: contract.methods.prizePeriodSeconds(),
        awards: contract.methods.getERC20Awards(),
      };
    });
    const ticketCalls: ShapeWithLabel[] = pots.map(pot => {
      const contract = new this.web3.eth.Contract(NonTransferableTicketAbi, pot.ticketAddress);
      return {
        id: pot.id,
        totalSupply: contract.methods.totalSupply(),
      };
    });

    const results = await multicall.all([potCalls, strategyCalls, ticketCalls]);
    const potResults: RawPotContractData[] = results[0];
    const strategyResults: RawStrategyContractData[] = results[1];
    const ticketResults: RawTicketContractData[] = results[2];

    return pots.map((pot, i) => {
      const potResult = potResults[i];
      const data: PotDataEntity = {
        totalSupply: potResult.totalSupply,
        unstakeableAfter: parseInt(potResult.unstakeableAfter),
      };

      const strategyResult = strategyResults[i];
      const strategy: PotStrategyEntity = {
        startedAt: parseInt(strategyResult.startedAt),
        endsAt: parseInt(strategyResult.endsAt),
        drawLength: parseInt(strategyResult.drawLength),
        numberOfWinners: parseInt(
          strategyResult.awards[RawStrategyAwardContractDataKeys.numberOfWinners]
        ),
        awards: strategyResult.awards[RawStrategyAwardContractDataKeys.collections].map(
          (collection, j): PotStrategyAwardEntity => ({
            tokenAddress: collection,
            numberOfAwards: parseInt(
              strategyResult.awards[RawStrategyAwardContractDataKeys.numberOfAwards][j]
            ),
            available: strategyResult.awards[RawStrategyAwardContractDataKeys.available][j],
            vault: strategyResult.awards[RawStrategyAwardContractDataKeys.vaults][j],
            numberOfTokensPerAward:
              strategyResult.awards[RawStrategyAwardContractDataKeys.numberOfTokensPerAwards][j],
          })
        ),
      };

      const ticketResult = ticketResults[i];
      const ticket: PotTicketEntity = {
        totalSupply: ticketResult.totalSupply,
      };

      return {
        id: pot.id,
        strategy: strategy,
        data: data,
        ticket: ticket,
      };
    });
  }
}
