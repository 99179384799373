import {
  combineReducers,
  configureStore,
  createListenerMiddleware,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit';
import { networkReducer } from './reducers/networks';
import { walletConnected, walletReducer, walletUnsupported } from './reducers/wallet';
import { tokensReducer } from './reducers/tokens';
import { potsReducer } from './reducers/pots';
import { dataLoaderReducer } from './reducers/data-loader';
import { balanceReducer } from './reducers/balance';
import { nftsReducer } from './reducers/nfts';
import { balanceFetchAll } from './actions/balance';
import { RootState } from './store-types';
import { allowanceReducer } from './reducers/allowance';
import { chanceReducer } from './reducers/chance';

const listenerMiddleware = createListenerMiddleware<RootState>();
listenerMiddleware.startListening({
  matcher: isAnyOf(walletConnected, walletUnsupported),
  effect: async (action: PayloadAction<{ address: string }>, { dispatch }) => {
    if (action.payload.address) {
      console.log('dispatch balance fetch from listener');
      dispatch(balanceFetchAll({ walletAddress: action.payload.address }));
    }
  },
});

export const store = configureStore<RootState>({
  reducer: {
    loader: dataLoaderReducer,
    entities: combineReducers({
      network: networkReducer,
      tokens: tokensReducer,
      pots: potsReducer,
      nfts: nftsReducer,
    }),
    user: combineReducers({
      wallet: walletReducer,
      balance: balanceReducer,
      allowance: allowanceReducer,
      chance: chanceReducer,
    }),
  },
  middleware: getDefaultMiddleware => {
    const middlewares = getDefaultMiddleware();
    return middlewares.prepend(listenerMiddleware.middleware) as any;
  },
});

export type AppDispatch = typeof store.dispatch;
