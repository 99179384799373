import { createAsyncThunk } from '@reduxjs/toolkit';
import { getConfigApi, getContractDataApiForNetwork } from '../api';
import { NetworkEntity } from '../entities/network';
import { PotConfig } from '../api/config/types';
import { selectNetworkById } from '../selectors/networks';
import { selectPotsByNetworkId } from '../selectors/pots';
import { PotContractData } from '../api/contract-data/types';
import { RootState } from '../store-types';

export const potsInit = createAsyncThunk<Record<NetworkEntity['id'], PotConfig[]>>(
  'pots/init',
  async () => {
    const api = await getConfigApi();
    return await api.getPots();
  }
);

export const potsFetchContractDataByNetwork = createAsyncThunk<
  PotContractData[],
  { networkId: NetworkEntity['id'] },
  { state: RootState }
>('pots/fetchContractDataByNetwork', async ({ networkId }, { getState }) => {
  const state = getState();
  const network = selectNetworkById(state, networkId);
  const pots = selectPotsByNetworkId(state, network.id);
  const api = await getContractDataApiForNetwork(network);
  return await api.fetchAllPotsData(pots);
});
