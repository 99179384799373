import { createAsyncThunk } from '@reduxjs/toolkit';
import { NetworkEntity } from '../entities/network';
import { TokenEntity } from '../entities/token';
import { selectTokenByNetworkAddress } from '../selectors/tokens';
import { getWeb3ForNetwork } from '../api';
import { selectNetworkById } from '../selectors/networks';
import { ERC721EnumerableAbi } from '../../config/abi';
import { RootState } from '../store-types';

export type AllowanceFetchArgs = {
  networkId: NetworkEntity['id'];
  tokenAddress: TokenEntity['address'];
  spenderAddress: string;
  walletAddress: string;
};

export type AllowanceFetchPayloadErc721 = {
  type: 'erc721';
  isApprovedForAll: boolean;
};

export type AllowanceFetchPayload = AllowanceFetchPayloadErc721;

export const allowanceFetch = createAsyncThunk<
  AllowanceFetchPayload,
  AllowanceFetchArgs,
  { state: RootState }
>(
  'allowance/fetch',
  async ({ networkId, tokenAddress, walletAddress, spenderAddress }, { getState }) => {
    const state = getState();
    const network = selectNetworkById(state, networkId);
    const token = selectTokenByNetworkAddress(state, networkId, tokenAddress);

    if (token.type === 'erc721') {
      return allowanceFetchErc721(token, network, walletAddress, spenderAddress);
    }

    throw new Error(`${token.type} not supported`);
  }
);

async function allowanceFetchErc721(
  token: TokenEntity,
  network: NetworkEntity,
  walletAddress: string,
  spenderAddress: string
): Promise<AllowanceFetchPayloadErc721> {
  const web3 = await getWeb3ForNetwork(network);
  const contract = new web3.eth.Contract(ERC721EnumerableAbi, token.address);
  const isApprovedForAll = await contract.methods
    .isApprovedForAll(walletAddress, spenderAddress)
    .call();

  return {
    type: 'erc721',
    isApprovedForAll,
  };
}
